import dynamic from 'next/dynamic';
import type {FormikProps} from 'formik';
import 'react-phone-number-input/style.css';
import {useLocale, useTranslations} from 'next-intl';
import {parsePhoneNumber} from 'react-phone-number-input';
import {getCountryCode} from 'src/utils/countryCodeUtils';
import {getBrandStyleCSS} from 'src/utils/getBrandStyle';
import FormInputWrapper from './FormInputWrapper';

const ReactPhoneInput: any = dynamic(() => import('react-phone-number-input'), {ssr: false});

const getInitialCountryCode = (phoneNumber: string, defaultValue: string) => {
  if (!phoneNumber) return defaultValue;

  const parsedNumber = parsePhoneNumber(phoneNumber);

  return parsedNumber?.country || defaultValue;
};

type Props = {
  brandStyle: BrandStyle;
  style?: React.CSSProperties;
  formikProps: FormikProps<any>;
  id: string;
  defaultCountryCode?: string;
};

const PhoneInput = ({brandStyle, style, formikProps, id, defaultCountryCode}: Props) => {
  const t = useTranslations();
  const locale = useLocale();

  const initialCountryCode = getInitialCountryCode(
    formikProps.values[id],
    defaultCountryCode || getCountryCode(locale)
  );

  const value = formikProps.values[id];
  const inputId = `${id}-input`;

  return (
    <FormInputWrapper
      brandStyle={brandStyle}
      id={id}
      label={initialCountryCode ? `${t('common.phone number')} · ${initialCountryCode}` : t('common.phone number')}
      errorMessage={formikProps.errors[id]}
      touched={formikProps.touched[id]}
      style={style}
    >
      <div
        style={
          {
            ...getBrandStyleCSS(brandStyle),
            '--PhoneInputCountryFlag-borderColor': 'transparent',
            '--PhoneInputCountrySelectArrow-borderWidth': '2px',
            '--PhoneInputCountrySelectArrow-width': '6px',
            '--PhoneInputCountrySelectArrow-marginTop': '-2px',
            '--PhoneInputCountryFlag-borderColor--focus': 'white',
            '--PhoneInputCountrySelectArrow-color--focus': 'white',
            '--PhoneInputCountrySelectArrow-opacity': 1
          } as React.CSSProperties
        }
        className="highlight-block py-[6px] [&_div.PhoneInputCountry]:focus-within:bg-[--brand-primary-color] [&_div.PhoneInputCountry]:focus-within:[--PhoneInputCountrySelectArrow-color:white]"
      >
        <ReactPhoneInput
          onBlur={(e) => formikProps.setFieldTouched(id, true)}
          defaultCountry={initialCountryCode}
          value={value}
          onChange={(v) => formikProps.setFieldValue(id, v || '')}
          className="[&_input]:text-lg [&_input]:bg-transparent [&_.PhoneInputCountry]:pr-[6px] [&_.PhoneInputCountry]:pl-1 [&_.PhoneInputCountry]:rounded"
          numberInputProps={{id: inputId}}
        />
      </div>
    </FormInputWrapper>
  );
};

export default PhoneInput;

import {motion} from 'motion/react';
import {twMerge} from 'tailwind-merge';
import {useTranslations} from 'next-intl';
import type {FormikErrors, FormikTouched} from 'formik';

type Props = {
  brandStyle: BrandStyle;
  id: string;
  label?: string;
  helperText?: string;
  optional?: boolean;
  errorMessage?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  setTouched?: (touched: boolean) => void;
  children: React.ReactNode;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  style?: React.CSSProperties;
  layout?: boolean;
  containerRef?: any;
  LeftComponent?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disableVisibleFocus?: boolean;
};

const FormInputWrapper = ({
  brandStyle,
  id,
  label,
  helperText,
  optional,
  errorMessage,
  children,
  touched,
  style,
  layout = true,
  containerRef,
  LeftComponent,
  onClick,
  className,
  disableVisibleFocus
}: Props) => {
  const t = useTranslations();

  const hasHelperContent = (errorMessage && touched) || optional || helperText;

  const standalone = !hasHelperContent;

  return (
    <motion.div
      className={twMerge(
        'mb-5 border-2 border-solid rounded-md bg-[--lighter-background] border-transparent focus-within:border-current transition-colors duration-200',
        disableVisibleFocus && 'focus-within:border-transparent',
        className
      )}
      onClick={onClick}
      ref={containerRef}
      layout={layout}
      style={{...style, color: brandStyle.primaryColor}}
    >
      <motion.div
        className={twMerge(
          'inputWrapper relative flex items-center rounded-t-md transition-colors border-dashed border-b-2 [&>*]:relative',
          label ? 'min-h-[60px]' : '',
          'after:[content-[""] after:absolute after:h-px after:left-0 after:right-0 after:-bottom-px after:border-b after:border-solid after:border-[#d1d5da]]',
          standalone
            ? 'rounded-md border-transparent after:border-b-transparent'
            : 'border-[#d1d5da] after:border-b-[--lighter-background]'
        )}
        layout={layout}
      >
        {LeftComponent}

        <div className="flex flex-col grow px-4 pt-[7px] pb-[2px]">
          {label ? (
            <label className="text-xs text-[--dark-text] min-h-[18px] font-medium" htmlFor={id}>
              {label}
            </label>
          ) : null}

          {children}
        </div>
      </motion.div>

      {hasHelperContent ? (
        <motion.div
          className="rounded-l-md rounded-r-md bg-[--lighter-background] py-2 px-4 text-xs leading-snug text-[--gray-text]"
          layout={layout}
        >
          {errorMessage && touched ? (
            <span className="font-medium text-[--error-color]">{String(errorMessage)}</span>
          ) : null}
          {errorMessage && touched && (optional || helperText) ? <span> • </span> : null}
          {optional && <span className="font-medium">{t('common.optional')}</span>}
          {helperText && (
            <span>
              {optional ? ' • ' : ''}
              {helperText}
            </span>
          )}
        </motion.div>
      ) : null}
    </motion.div>
  );
};

export default FormInputWrapper;
